/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function($) {

  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  var Sage = {
    // All pages
    'common': {
      init: function() {
        // JavaScript to be fired on all pages
        $(document).foundation(); // Foundation JavaScript

        /*
        * Slick Carousel
        */
        $(document).ready(function(){
	        $('.hero-slider').slick({
	        	slidesToShow: 1,
					  slidesToScroll: 1,
					  autoplay: true,
					  autoplaySpeed: 5000,
				    fade: true,
				    dots: false,
				    arrows: false
				  });
			  });

        /*
				* Disable body scroll when off-canvas is opened
				*/
				$(".off-canvas").on("opened.zf.offcanvas", function(e) {
					$('body').addClass('offcanvas-open');
					setTimeout(function(){
					  $('.close-button').removeClass('hide');
					}, 500);
				});

				$(".off-canvas").on("closed.zf.offcanvas", function(e) {
					$('body').removeClass('offcanvas-open');
					$('.close-button').addClass('hide');
				});


        /*
        * Check URL for hash
        */
        if(window.location.hash) {
        	var hash = window.location.hash.substring(1);

		      $('html, body').animate({
				    scrollTop: $('#' + hash).offset().top - 60
				  }, {
				    duration: 1000,
				    complete: function() {
				      $('#' + hash).trigger('click');
				    }
				  });
		  	}


		  	/*
		  	* Pin logo
		  	*/
		  	var controller = new ScrollMagic.Controller();

		  	var logoAnim = new TimelineMax();
      			logoAnim.to($('.logo'), 0.4, {height: 65, ease: Linear.easeInOut}, 0);
      			logoAnim.to($('.logo-container'), 0.3, {backgroundColor: 'rgba(245,245,245,1)', ease: Linear.easeNone}, 0.4);
      			logoAnim.to($('.facebook'), 0.3, {opacity: 1, ease: Linear.easeNone}, 0.4);
      			logoAnim.to($('.contact'), 0.3, {opacity: 1, ease: Linear.easeNone}, 0.4);

		  	var pinLogo = new ScrollMagic.Scene({
		  		triggerHook: "onLeave",
		  		triggerElement: ".logo-container",
		  		offset: 0
		  	}).setPin(".logo-container")
		  	.setTween(logoAnim)
		  	.addTo(controller);


        /*
        * Grid item overlay & item hover with GSAP
        */
        // Overall overlay animation
        var overlayAnim = new TimelineMax({paused: true});

        overlayAnim.to($('.overlay'), 0.4, {opacity: 1}, 0.1);
        overlayAnim.set($('.overlay'), {zIndex: 5}, 0);

        //Single item hover animations
        $('.grid__item').each(function() {
        	var hoverAnim = new TimelineMax({paused: true});

        	hoverAnim.to($(this).find('.grid__item__text .anim-fill'), 0.4, {top: 0, ease: Power3.easeInOut}, 0);

	        $(this).hover(
        		function() {
        			hoverAnim.play();
        		}, function() {
        			hoverAnim.reverse();
        		}
        	);
		    });


        // Single item overlay animations
        $('.grid__item').each(function() {
        	var singleElementButton = $(this).find('.grid__item__button'),
							newItemUrl = $(this).find('.grid__item__button').data('link'),
	        		newItemId = $(this).find('.grid__item__button').data('id'),
	        		newItemSlug = $(this).data('slug');

        	$(this).on('click', function() {
	        	if (!$('body').hasClass('overlay-open')) {
	        		$('body').addClass('overlay-open');
	        		$('.overlay').find('.overlay__inner__item.' + newItemId).addClass('active');

	        		if(history.pushState) {
						    history.pushState(null, null, '#' + newItemSlug);
							} else {
						    location.hash = '#' + newItemSlug;
							}

	        		overlayAnim.play();
	        	}
	        });

	        $('.overlay__inner').click(function(e) {
	        	if (e.target === this){

		        	if ($('body').hasClass('overlay-open')) {
			        	$('body').removeClass('overlay-open');

			        	if(history.pushState) {
							    history.pushState(null, null, '#');
								} else {
							    location.hash = '#';
								}

			        	overlayAnim.reverse();

							  $('.overlay__inner__item').removeClass('active');
							}
						}
					});

					$('.overlay__inner .close').click(function() {
	        	if ($('body').hasClass('overlay-open')) {
		        	$('body').removeClass('overlay-open');

		        	if(history.pushState) {
						    history.pushState(null, null, '#');
							} else {
						    location.hash = '#';
							}

		        	overlayAnim.reverse();
						  $('.overlay__inner__item').removeClass('active');
						}
					});

        });

        $('.grid__item__button').click(function(e) {
        	e.preventDefault();

        	var url = $(this).attr('href');
        	window.open(url, '_blank');

        	return false;
        });

      },
      finalize: function() {
        // JavaScript to be fired on all pages, after page specific JS is fired
      }
    },
    // Home page
    'home': {
      init: function() {
        // JavaScript to be fired on the home page
      },
      finalize: function() {
        // JavaScript to be fired on the home page, after the init JS
      }
    },
    // About us page, note the change from about-us to about_us.
    'about_us': {
      init: function() {
        // JavaScript to be fired on the about us page
      }
    }
  };

  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
    fire: function(func, funcname, args) {
      var fire;
      var namespace = Sage;
      funcname = (funcname === undefined) ? 'init' : funcname;
      fire = func !== '';
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === 'function';

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function() {
      // Fire common init JS
      UTIL.fire('common');

      // Fire page-specific init JS, and then finalize JS
      $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
        UTIL.fire(classnm);
        UTIL.fire(classnm, 'finalize');
      });

      // Fire common finalize JS
      UTIL.fire('common', 'finalize');
    }
  };

  // Load Events
  $(document).ready(UTIL.loadEvents);

})(jQuery); // Fully reference jQuery after this point.